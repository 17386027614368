.lp21-reporting-document td.lp21-kompetenzstufe>div.row>div:first-child {
    display: none;
}

.lp21-reporting-document .lp21-table {
    font-size: 11px;
}

.lp21-reporting-document td.lp21-customColumn-kompetenzstufe {
    width: 50% !important;
    vertical-align: top;
}


.lp21-reporting-document td.lp21-customColumn-kompetenzstufe .lp21-assessmentschemalevel {
    margin-top: 0px;
}

.lp21-reporting-document td.lp21-customColumn-kompetenzstufe .lp21-assessmentschemalevel .lp21-level {
    height: 30px;
}

.lp21-reporting-document .lp21-assessmentschemalevel .lp21-outer {
    padding: 0 2px;
}

.lp21-reporting-document .lp21-assessmentschemalevel .lp21-level {
    height: 80%;
}

.lp21-reporting-document .lp21-assessmentschemalevel .lp21-legend {
    font-size: 0.8em;
    align-items: flex-start;
}

.lp21-reporting-document .lp21-reporting-richtext table {
    break-inside: avoid;
}

.lp21-reporting-document .lp21-reporting-richtext table td {
    border: black 1px solid;
}

div.lp21-reporting-document h1:first-of-type {
    margin-top: 2em;
}

div.lp21-reporting-document .lp21-table .lp21-fach {
    break-after: avoid;
}