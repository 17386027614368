.lp21-assessmentitemspage td.lp21-customColumn-fachbereich,
.lp21-assessmentitemspage td.lp21-customColumn-fach,
.lp21-assessmentitemspage td.lp21-customColumn-kompetenzbereich,
.lp21-assessmentitemspage td.lp21-customColumn-handlungsaspekt,
.lp21-assessmentitemspage td.lp21-customColumn-kompetenz,
.lp21-assessmentitemspage td.lp21-customColumn-kompetenzstufe {
    width: 50%;
    padding-left: 20px;
    border-left: 1px solid #EFF2F5;
}

.lp21-assessmentitemspage td.lp21-customColumn-kompetenz {
    vertical-align: bottom;
}

.lp21-assessmentitemspage .lp21-customColumn-kompetenz-planning {
    margin-bottom: 20px;
}

.lp21-assessmentitemspage .lp21-customColumn-kompetenzstufe label.form-label {
    display: none;
}

.lp21-assessmentitemspage .lp21-customColumn-planning {
    margin-top: 10px;
    font-size: 14px;
    font-weight: 400;
}

.lp21-assessmentitemspage .lp21-customColumn-planning label.form-label {
    display: inherit;
    text-align: left;
    font-weight: 500;
    border-bottom: rgb(193, 221, 240) 1px solid;
    margin: 0 -13px 10px -13px;
    padding: 0 13px 10px 13px;
}