.lp21-planitemspage td.lp21-customColumn-fachbereich,
.lp21-planitemspage td.lp21-customColumn-fach,
.lp21-planitemspage td.lp21-customColumn-kompetenzbereich,
.lp21-planitemspage td.lp21-customColumn-handlungsaspekt,
.lp21-planitemspage td.lp21-customColumn-kompetenz,
.lp21-planitemspage td.lp21-customColumn-kompetenzstufe {
    width: 50%;
    padding-left: 20px;
    border-left: 1px solid #EFF2F5;
}

.lp21-planitemspage td.lp21-customColumn-kompetenz {
    vertical-align: bottom;
}

.lp21-planitemspage td.lp21-customColumn-kompetenzstufe .lp21-assessmentschemalevel {
    padding: 10px;
}

.lp21-planitemspage td.lp21-customColumn-kompetenzstufe .lp21-assessmentschemalevel .lp21-level {
    min-height: 50px;
}

.lp21-planitemspage td.lp21-customColumn-kompetenzstufe .lp21-assessmentschemalevel .lp21-outer.lp21-delete {
    display: none;
}

.lp21-planitemspage .lp21-customColumn-kompetenz-planning {
    margin-bottom: 20px;
}