div.lp21-fileupload input {
    /* display: none; */
    width: 0.1px;
	height: 0.1px;
	opacity: 0;
	overflow: hidden;
	position: absolute;
	z-index: -1;
}

div.lp21-fileupload .lp21-small
{
    padding-right: 0;
}

/*
div.lp21-fileupload input+label {
    font-size: 1.25em;
    font-weight: 700;
    color: white;
    background-color: black;
    display: inline-block;
}

div.lp21-fileupload input:focus+label,
div.lp21-fileupload input+label:hover {
    background-color: red;
    outline: 1px dotted #000;
    outline: -webkit-focus-ring-color auto 5px;
    pointer-events: none;
}

*/