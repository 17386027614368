#lp21_navigation_toggle {
    position: fixed;
    top: 80px;
    left: 0;
    z-index: 105;
}

@media (min-width: 992px) {
    #lp21_navigation_toggle {
        left: 270px;
    }
}

#lp21_navigation_toggle.card {
    border-left: 1px #EFF2F5 solid;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}

#lp21_navigation_toggle .collapsing {
    transition: none !important;
}

#lp21_navigation_toggle .card-header {
    cursor: pointer;
}

#lp21_navigation_toggle .card-header,
#lp21_navigation_toggle .card-header i {
    color: #2F8FCE;
}

#lp21_navigation_toggle .card-header i.bi-chevron-double-left {
    margin-right: 5px;
}

#lp21_navigation_toggle .card-header.collapsed i.bi-chevron-double-left::before {
    content: "\f280";
}

#lp21_navigation_toggle .card-header h3 span {
    margin-left: 10px;
    color: #2F8FCE;
}

#lp21_navigation_toggle .card-header.collapsed h3 span {
    display: none;
}

#lp21_navigation {
    width: 200px;
}

#lp21_navigation_toggle .card-scroll {
    overflow-y: auto;
    overflow-x: hidden;
    max-height: 500px;
}

@media screen and (max-height: 730px) {

    #lp21_navigation_toggle .card-scroll {
        max-height: 350px;
    }
}

@media screen and (max-height: 560px) {

    #lp21_navigation_toggle .card-scroll {
        max-height: 150px;
    }
}

#lp21_navigation small {
    margin-right: 10px;
}

#lp21_navigation .lp21-fach {
    margin-top: 20px;
    font-size: 1.1em;
}

#lp21_navigation .lp21-kompetenzbereich {
    font-size: 0.9em;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

#lp21_navigation #lp21_navigation_more {
    font-size: 0.8em;
}

#lp21_navigation #lp21_navigation_more .lp21-navigation-loadall {
    margin-top: 20px;
}