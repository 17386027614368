table.lp21-copy thead td:nth-child(2n+1) {}

table.lp21-copy thead td:nth-child(2n+0) {
    vertical-align: middle;
    text-align: center;
}

table.lp21-copy tbody td {
    vertical-align: middle;
    text-align: center;
}

table.lp21-copy-overwrite tbody td.lp21-overridable {
    color: #ffc700;
}

div.lp21-copysettings .mb-10 {
    margin-bottom: 10px !important;
}