//
// To make future updates easier consider overriding the global variables from _variables.bootstrap.scss and _variables.custom.scss for current demo in this file.
// Note that this file is included first and variables defined in _variables.bootstrap.scss and _variables.custom.scss
// are not accessible in this file but you can override any global variable as shown below:
//

$zyklus1: #E39B2D;
$zyklus2: #2F8FCE;
$zyklus3: #97C941;

// Theme colors
// Primary
$primary:       									#2F8FCE;
$primary-active:    								#1c567d;
$primary-light:    									#EAF4FA;
$primary-inverse:  									#FFFFFF;

// Success
$success:       									#97c941;
$success-active:    								#759F2D;
$success-light:    									#F4F9EB;
$success-inverse:  									#FFFFFF;

// Info
$info:       									    #5710B2;
$info-active:    									#41058E;
$info-light:    									#F6F1FC;
$info-inverse:  									#FFFFFF;

// Danger
$danger:       									    #f02828;
$danger-active:    									#A70C0C;
$danger-light:    									#FDE7E7; 
$danger-inverse:  									#FFFFFF;

// Warning
$warning:       									#e39b2d;
$warning-active:    								#CA841C;
$warning-light:    									#FCF4E9;
$warning-inverse:  									#FFFFFF;

// Border Radiues
$border-radius-sm:            						.3rem;
$border-radius:               						.65rem;
$border-radius-lg:            						.85rem;
$border-radius-xl:                                  1.25rem;

// Card Box Shadow
$card-box-shadow:                                   0px 0px 20px 0px rgba(76,87,125,0.02);
