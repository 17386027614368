.lp21-assessmentsummary {}


.lp21-assessmentsummary .lp21-legend {
    width: 100%;
    display: flex;
    align-content: center;
    justify-content: center;
    flex-wrap: nowrap;
    margin-left: auto;
    margin-right: auto;
}


.lp21-assessmentsummary .lp21-legend .lp21-label {
    padding-right: 20px;
    color: #A1A5B7;
}


.lp21-assessmentsummary .lp21-legend .lp21-percentage {
    padding-right: 10px;
}