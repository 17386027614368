#lp21_filter_toggle {
    position: fixed;
    top: 80px;
    right: 0;
    z-index: 105;
}

#lp21_filter_toggle.card {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}

#lp21_filter_toggle .collapsing {
    transition: none !important;
}

#lp21_filter_toggle .card-header {
    cursor: pointer;
}

#lp21_filter_toggle .card-header,
#lp21_filter_toggle .card-header i {
    color: #2F8FCE;
}

#lp21_filter_toggle .card-header i.bi-chevron-double-right {
    margin-left: 5px;
}

#lp21_filter_toggle .card-header.collapsed i.bi-chevron-double-right::before {
    content: "\f27f";
}

#lp21_filter_toggle .card-header h3 span {
    margin-right: 10px;
    color: #2F8FCE;
}

#lp21_filter_toggle .card-header.collapsed h3 span {
    display: none;
}

#lp21_filter {
    width: 350px;
}

#lp21_filter div.row:not(:first-of-type) h4 {
    margin-top: 20px;
}

#lp21_filter div.row>div.mb-10 {
    margin-bottom: 5px !important;
}


#lp21_filter div.row>div.mb-10>label {
    font-size: 0.925rem;
    margin-bottom: 2px !important;
}

#lp21_filter div.row>div.mb-10 .form-check-label {
    font-size: 0.925rem;
}

#lp21_filter div.row>div.mb-10 .form-check-input {
    margin-top: 0px;
    height: 1.5rem;
    width: 1.5rem;
}

#lp21_filter div.row>div.mb-10 .form-switch .form-check-input {
    margin-top: 0px;
    height: 1.2rem;
    width: 2.2rem;
}

#lp21_filter .lp21-savedfilters button i {
    color: #2F8FCE;
}

#lp21_filter .lp21-savedfilters button:disabled i {
    color: inherit;
}

#lp21_filter_toggle .card-scroll {
    overflow-y: auto;
    overflow-x: hidden;
}

@media screen and (max-height: 730px) {

    #lp21_filter_toggle .card-scroll {
        max-height: 285px;
    }
}

@media screen and (max-height: 560px) {

    #lp21_filter_toggle .card-scroll {
        max-height: 150px;
    }
}