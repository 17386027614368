.lp21-assessmentschemalevel {
    height: 100%;
}

.lp21-assessmentschemalevel .lp21-outer {
    display: flex;
    flex-direction: column;
    flex: 1;
    justify-content: flex-end;
    padding: 0 5px;
}

.lp21-assessmentschemalevel .lp21-outer.react-tooltip-wrapper {
    padding: 0;
}

.lp21-assessmentschemalevel .lp21-outer.disabled,
.lp21-assessmentschemalevel .lp21-outer.disabled .lp21-inner {
    cursor: default;
}

.lp21-assessmentschemalevel .lp21-inner {
    background-color: #CCCCC7;
    height: 100%;
}

.lp21-assessmentschemalevel .lp21-inner.hovered {
    background-color: #1c567d;
}

.lp21-assessmentschemalevel .lp21-inner.hovered-implicitly {
    background-color: #1c567d;
}

.lp21-assessmentschemalevel .lp21-inner.selected {
    background-color: #2F8FCE;
}

.lp21-assessmentschemalevel .lp21-inner.selected-implicitly {
    background-color: #2F8FCE;
}

.lp21-assessmentschemalevel .lp21-inner.selected.hovered,
.lp21-assessmentschemalevel .lp21-inner.selected-implicitly.hovered {
    background-color: #1c567d;
}


.lp21-assessmentschemalevel .lp21-outer.lp21-delete .lp21-inner {
    background-color: transparent;
}

.lp21-assessmentschemalevel .lp21-outer.lp21-delete .lp21-inner.hovered {
    max-height: 30% !important;
    text-align: center;
}

.lp21-assessmentschemalevel .lp21-outer.lp21-delete .lp21-inner.hovered::after {
    font-family: bootstrap-icons !important;
    font-size: 1.2em;
    content: "";
}

.lp21-assessmentschemalevel .lp21-level {
    display: flex;
    height: 100%;
}

.lp21-assessmentschemalevel .lp21-legend {
    display: flex;
    align-items: center;
    vertical-align: top;
    justify-content: center;
    text-align: center;
    height: 100%;
}