div.lp21-userpage {
    display: grid;
    grid-template-columns: 50% 50%;
    column-gap: 50px;
}

div.lp21-userpage h4 {
    margin-bottom: 50px;
}

.lp21-userpage .lp21-userpage-icon {
    align-self: center;
    text-align: right;
    margin-bottom: 4px;
}

.lp21-userpage .lp21-userpage-icon i {
    margin-right: 10px;
}

.lp21-userpage .lp21-userpage-button {
    align-self: center;
    margin-bottom: 4px;
    text-align: right;
}