button.lp21-button {
    background: none;
    border: none;
}

button.lp21-button:hover .text-primary {
    color: #1c567d !important;
}

button.lp21-button.lp21-editdetail, 
button.lp21-button.lp21-print, 
button.lp21-button.lp21-delete, 
button.lp21-button.lp21-more {
    margin-left: 10px;
}

.lp21-form-check-outer {
    display: flex;
}

.lp21-form-check-outer>div.form-check {
    margin-right: 18px;
}