/* 
    BEGIN General
*/

table.lp21-table-basic p:last-child {
    margin-bottom: 0;
}

table.lp21-table-basic .lp21-remark {
    color: #2F8FCE;
}

/* 
    END General
*/

/* 
    BEGIN Hierarchie (Fachbereich/Fach, Kompetenzbereich, Handlungsaspekt)
*/

table.lp21-table-basic tr.lp21-fach td {
    background-color: white;
    padding-top: 20px;
    padding-bottom: 0;
    color: #CD202C;
    font-size: 1.5em;
    font-weight: bold;
}

table.lp21-table-basic tr.lp21-kompetenzbereich td {
    background-color: white;
    font-size: 1.4em;
    font-weight: bold;
    padding-top: 10px;
}

table.lp21-table-basic tr.lp21-handlungsaspekt td {
    font-size: 1.1em;
}

/* 
    END Hierarchie (Fachbereich/Fach, Kompetenzbereich, Handlungsaspekt)
*/

/* 
    BEGIN Kompetenz/Aufbau
*/

table.lp21-table-basic tr.lp21-kompetenz td {
    background-color: white;
    font-size: 1.1em;
    font-weight: bold;
}

/* 
    END Kompetenz/Aufbau
*/

/* 
    BEGIN Kompetenzstufe/Aufzaehlungspunkt/Orienterungspunkte
*/

table.lp21-table-basic tr.lp21-kompetenzstufe.lp21-grundanspruch td,
table.lp21-table-basic tr.lp21-orientierungspunkt.lp21-grundanspruch td {
    /* Grundanspruch */
    background-color: #DDDDD7;
}

table.lp21-table-basic tr.lp21-zyklus-linieoben-1 td:not(.lp21-zyklus) {
    border-top: solid 3px #E39B2D;
}

table.lp21-table-basic tr.lp21-zyklus-linieoben-2 td:not(.lp21-zyklus) {
    border-top: solid 3px #2F8FCE;
}

table.lp21-table-basic tr.lp21-zyklus-linieoben-3 td:not(.lp21-zyklus) {
    border-top: solid 3px #97C941;
}

table.lp21-table-basic tr.lp21-zyklus-linieunten-1 td:not(.lp21-zyklus) {
    border-bottom: solid 3px #E39B2D;
}

table.lp21-table-basic tr.lp21-zyklus-linieunten-2 td:not(.lp21-zyklus) {
    border-bottom: solid 3px #2F8FCE;
}

table.lp21-table-basic tr.lp21-zyklus-linieunten-3 td:not(.lp21-zyklus) {
    border-bottom: solid 3px #97C941;
}

/* 
    END Kompetenzstufe/Aufzaehlungspunkt/Orienterungspunkte
*/

/* 
    BEGIN Kompetenzstufe/Aufzaehlungspunkt
*/

table.lp21-table-basic tr.lp21-kompetenzstufe td.lp21-kompetenzstufe i {
    color: inherit;
}

table.lp21-table-basic tr.lp21-kompetenzstufe td.lp21-kompetenzstufe p.lp21-begriffe i {
    color: #CD202C;
    font-size: 1.1em;
}

table.lp21-table-basic tr.lp21-kompetenzstufe td.lp21-kompetenzstufe p.lp21-kompetenzstufe-html img {
    min-height: 14px;
    margin-bottom: 5px;
}


/* 
    END Kompetenzstufe/Aufzaehlungspunkt
*/

/* 
    BEGIN Orientierungspunkt
*/

table.lp21-table-basic tr.lp21-orientierungspunkt td {
    padding: 0 !important;
}

table.lp21-table-basic tr.lp21-orientierungspunkt td hr {
    border-top: dotted 3px #CB3A27;
    background-color: transparent;
    opacity: unset;
}

/* 
    END Orientierungspunkt
*/

/* 
    BEGIN Spaeter im Zyklus
*/

table.lp21-table-basic tr.lp21-spaeterImZyklus td {
    padding-top: 15px;
}

table.lp21-table-basic tr.lp21-spaeterImZyklus td i {
    font-size: 2em;
    margin-left: 30px;
}

/* 
    END Orientierungspunkt
*/

/* 
    BEGIN Zyklus-Spalte
*/

table.lp21-table-basic tr.lp21-kompetenzstufe td.lp21-zyklus,
table.lp21-table-basic tr.lp21-spaeterImZyklus td.lp21-zyklus {
    width: 40px;
    min-width: 40px;
    max-width: 40px;
    padding: 0 !important;
    overflow-x: hidden;
    text-align: center;
    color: white;
    font-size: 2.7em;
    font-weight: bold;
    font-family: Poppins, Helvetica, "sans-serif";
}

table.lp21-table-basic tr.lp21-orientierungspunkt td.lp21-zyklus {
    text-align: center;
    vertical-align: middle;
}

table.lp21-table-basic tr.lp21-orientierungspunkt td.lp21-zyklus i {
    color: white;
    font-size: 1.6em;
}

table.lp21-table-basic tr td.lp21-zyklus.lp21-zyklus-12,
table.lp21-table-basic tr td.lp21-zyklus.lp21-zyklus-23 {
    width: 20px;
    min-width: 20px;
    max-width: 20px;
}

table.lp21-table-basic tr td.lp21-zyklus.lp21-zyklus-1,
table.lp21-table-basic tr td.lp21-zyklus.lp21-zyklus-12.lp21-zyklus-right {
    background-color: #E39B2D;
}

table.lp21-table-basic tr td.lp21-zyklus.lp21-zyklus-2,
table.lp21-table-basic tr td.lp21-zyklus.lp21-zyklus-23.lp21-zyklus-right,
table.lp21-table-basic tr td.lp21-zyklus.lp21-zyklus-12.lp21-zyklus-left {
    background-color: #2F8FCE;
}

table.lp21-table-basic tr td.lp21-zyklus.lp21-zyklus-3,
table.lp21-table-basic tr td.lp21-zyklus.lp21-zyklus-23.lp21-zyklus-left {
    background-color: #97C941;
}

/* 
    END Zyklus-Spalte
*/