div.lp21-assessmentimagemanager img {
    object-fit: contain;
}

div.lp21-assessmentimagemanager .card {
    margin-bottom: 20px;
}

div.lp21-assessmentimagemanager .overlay-layer
{
    padding-right: 3px;
}

div.lp21-assessmentimagemanager .overlay-layer a.btn {
    padding: 5px !important;
    margin: 5px 2px !important;
}

div.lp21-assessmentimagemanager .overlay-layer a.btn>i {
    padding-right: 0
}