div.lp21-filter {
    display: grid;
    grid-template-columns: auto max-content;
    align-items: center;
    gap: 20px;
    padding: 20px;
}

div.lp21-filter .mb-5 {

    margin-bottom: 0;
}