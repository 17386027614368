th.lp21-command,
td.lp21-command {
    width: 1%;
    white-space: nowrap;
}

div.lp21-buttons {
    display: flex;
    justify-content: flex-end;
}

div.lp21-buttons button {
    margin-left: 20px;
}

table.table-striped th {
    white-space: nowrap;
}

td span.lp21-icon i {
    margin-left: 5px;
    font-size: 1.1em;
}

#kt_aside {
    z-index: 106;
}

.disabled-link {
    pointer-events: none;
    color: #E4E6EF !important;
}

.disabled-link>i {
    color: #E4E6EF !important;
}