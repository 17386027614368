@media print {
    body.lp21-report {
        -webkit-print-color-adjust: exact;
        -moz-print-color-adjust: exact;
        -ms-print-color-adjust: exact;
        print-color-adjust: exact;
    }
}

body.lp21-report .card {
    max-width: 21cm;
}

div.lp21-reporting-document {
    max-width: 1000px;
    font-family: Calibri, sans-serif;
    font-size: 11pt;
}

div.lp21-reporting-document h1 {
    font-family: Corbel, sans-serif;
    font-size: 16pt;
    margin-top: 2em;
    margin-bottom: 1em;
    break-after: avoid;
}

div.lp21-reporting-document h1:first-of-type {
    margin-top: 0;
}

div.lp21-reporting-document h1>span {
    min-width: 1.2em;
    display: inline-block;
}

div.lp21-reporting-document h2 {
    font-family: Corbel, sans-serif;
    font-size: 13pt;
    margin-top: 1em;
    margin-bottom: 0.4em;
    break-after: avoid;
}

div.lp21-reporting-document h2:first-of-type {
    margin-top: 0;

}

div.lp21-reporting-document h2>span {
    min-width: 2em;
    display: inline-block;
}

div.lp21-reporting-document h3 {
    font-size: 1em;
}

div.lp21-reporting-document p:last-of-type {
    margin-bottom: 0;
}

div.lp21-reporting-document table.lp21-reporting-table {
    width: 100%;
    border: 0;
}

div.lp21-reporting-document table.lp21-reporting-table td {
    vertical-align: top;
    padding: 0 20px 20px 0;
}

div.lp21-reporting-document table.lp21-reporting-table td:last-child {
    padding-right: 0;
}

div.lp21-reporting-document table.lp21-reporting-table td.lp21-col50 {
    width: 50%
}

div.lp21-reporting-document .lp21-summary {
    margin-bottom: 3em;
    page-break-after: always;
}

div.lp21-reporting-document .lp21-keeptogether,
div.lp21-reporting-document .lp21-keeptogether * {
    break-inside: avoid;
}

div.lp21-reporting-document .lp21-keeptogether .lp21-reporting-richtext {
    page-break-inside: auto;
    -webkit-column-break-inside: auto;
    break-inside: auto;
}

div.lp21-reporting-document .lp21-keeptogether .lp21-reporting-richtext * {
    page-break-inside: avoid;
    -webkit-column-break-inside: avoid;
    break-inside: avoid;
}

div.lp21-reporting-document .lp21-summary img {
    /* max-width: 100%; */
    object-fit: cover;
}

div.lp21-reporting-document table.lp21-summary-images {
    width: 100%;
    margin-bottom: 20px;
}

div.lp21-reporting-document table.lp21-summary-images td {
    padding-top: 0;
    padding-right: 0;
    padding-left: 0;
    padding-bottom: 20px;
}

div.lp21-reporting-document table.lp21-summary-images tr {
    break-inside: avoid;
}

div.lp21-reporting-document table.lp21-summary-images td.lp21-col100 {
    width: 100%;
}

div.lp21-reporting-document table.lp21-summary-images td.lp21-col50 {
    width: 50%;
}

div.lp21-reporting-document table.lp21-summary-images td.lp21-col50:first-of-type {
    padding-right: 10px;
}

div.lp21-reporting-document table.lp21-summary-images td.lp21-col50:last-of-type {
    padding-left: 10px;
}

div.lp21-reporting-document table.lp21-summary-images div.ratio img {
    object-fit: cover;
}

div.lp21-reporting-document .lp21-summary-fach .lp21-summary-remark {
    padding-top: 1em;
}

div.lp21-reporting-document .lp21-summary .lp21-summary-fach {
    margin-bottom: 30px;
}

div.lp21-reporting-document .lp21-summary .lp21-summary-kompetenzbereich {
    row-gap: 50px;
}

div.lp21-reporting-document .lp21-summary .lp21-summary-kompetenzbereich-short {
    text-align: center;
    width: 50%;
    display: inline-block;
    margin-bottom: 50px;
}


div.lp21-reporting-document .lp21-summary .lp21-summary-kompetenzbereich-short,
div.lp21-reporting-document .lp21-summary .lp21-summary-kompetenzbereich-short * {
    break-inside: avoid;
}

div.lp21-reporting-document .lp21-summary .lp21-summary-kompetenzbereich h3 {
    min-height: 2.5em;
}

div.lp21-reporting-page {
    justify-content: center;
    page-break-after: always;
    margin-top: 5rem;
    margin-left: 5rem;
    margin-right: 5rem;
}

div.lp21-reporting-coverpage .lp21-title {
    font-family: Corbel, sans-serif;
    font-size: 36pt;
    text-align: right;
    padding-top: 30rem;
    padding-bottom: 2rem;
}

div.lp21-reporting-coverpage .lp21-subtitle {
    font-family: Corbel, sans-serif;
    font-size: 22pt;
    text-align: right;
    color: #5A5A5A;
    padding-bottom: 10rem;
}

div.lp21-reporting-coverpage .lp21-date {
    font-family: Corbel, sans-serif;
    font-size: 14pt;
    text-align: right;
    color: #5A5A5A;
}

div.lp21-reporting-coverpage .lp21-author {
    font-family: Corbel, sans-serif;
    font-size: 14pt;
    text-align: right;
    color: #5A5A5A;
    padding-bottom: 10rem;
}

div.lp21-reporting-coverpage .lp21-footer {
    font-family: Corbel, sans-serif;
    font-size: 11pt;
}